import client from "../../client";

const Api = {
  getAuthorizeInfo:
    "/api/OpenPlatformService/ShopAuthorizeApp/GetAuthorizeAppByAppId",
  agreeAuthorize: "/api/OpenPlatformService/ShopAuthorizeApp/AgreeAuthorize",
  refuseAuthorize: "/api/OpenPlatformService/ShopAuthorizeApp/RefuseAuthorize",
};

export const getAuthorizeInfo = (appId) => {
  return client.request({
    url: Api.getAuthorizeInfo,
    params: {
      appId,
    },
    method: "get",
  });
};

export const agreeAuthorize = ({
  appId,
  applyAuthorizeKeys,
  askAuthorizeKeys,
  timestamp,
  callback,
  sign,
  queryStr,
}) => {
  return client.request({
    url: Api.agreeAuthorize + queryStr,
    data: {
      appId,
      applyAuthorizeKeys,
      askAuthorizeKeys,
      timestamp,
      callback,
      sign,
    },
    method: "post",
  });
};

export const refuseAuthorize = ({
  appId,
  applyAuthorizeKeys,
  askAuthorizeKeys,
  timestamp,
  sign,
  callback,
  queryStr,
}) => {
  return client.request({
    url: Api.refuseAuthorize + queryStr,
    data: {
      appId,
      applyAuthorizeKeys,
      askAuthorizeKeys,
      callback,
      timestamp,
      sign,
    },
    method: "post",
  });
};
