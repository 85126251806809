<template>
  <div class="auth-wrap">
    <a-card class="light-shadow"
            :bordered="false">
      <template #title>
        <span> {{$t('account.service_authority')}} </span>
      </template>
      <a-row :gutter=[36,12]>
        <a-col :span="12">
          <div>
            <p class="text-center">{{$t('account.through_authorization_the_application_will_gain_access_to_your_API')}}</p>
            <div class="text-center"
                 v-if="data.logo">
              <CFileDisplay :fileUrl="data.logo"
                            :disImgPreview="true"></CFileDisplay>
              <p class="mt-2">{{data.appName}}</p>
            </div>
            <div class="p-5 pt-3">
              <small style="color: rgb(153, 153, 153)">
                {{data.description}}
              </small>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <p>{{$t('account.after_authorization_you_will_be_granted_the_following_permissions')}}</p>
          <div class="p-1 pt-0 ">
            <a-row :gutter=[0,6]>
              <a-col :span="24"
                     v-for="item in data.authorizeInfoes"
                     :key="item.authorizeKey">
                <CheckCircleOutlined class="text-primary" />
                {{item.authorizeName}}
              </a-col>
            </a-row>
          </div>

        </a-col>
      </a-row>
      <a-row type="flex"
             justify="center"
             class="mt-3"
             v-if="data.askAuthorizeKeys && data.askAuthorizeKeys.length >0">
        <a-col>
          <a-button class="mr-4"
                    :loading="data.loading"
                    @click="handleRefuse"
                    const>{{$t('account.refuse')}}</a-button>
        </a-col>
        <a-col>
          <a-button type="primary"
                    :loading="data.loading"
                    @click="handleAgree">{{$t('account.agree_authorize')}}</a-button>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Card, Checkbox
} from "ant-design-vue";
import CFileDisplay from "../components/CFileDisplay.vue";
import { getAuthorizeInfo, agreeAuthorize, refuseAuthorize } from "../../api/modules/account/auth";
import urlHelper from "../../utils/urlHelper";

export default {
  name: `Auth`,
  components: {
    CFileDisplay,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ACard: Card,
    ACheckboxGroup: Checkbox.Group,
    ACheckbox: Checkbox,
  },
  setup () {
    const router = useRouter();
    const route = useRoute();

    const data = reactive({
      askAuthorizeKeys: [],//选中的选项
      appId: null,
      logo: null,
      appName: null,
      callback: null,
      description: null,
      id: null,
      authorizeInfoes: [],
      sign: null,
      timestamp: null,
      loading: false,
      queryStr: null,
    })


    const funcGetAuthorizeInfo = () => {
      if (!data.askAuthorizeKeys || data.askAuthorizeKeys?.length == 0) {
        return
      }
      getAuthorizeInfo(data.appId).then(({ result }) => {
        Object.assign(data, result);
        let newKey = [];
        data.authorizeInfoes = data.authorizeInfoes.filter(x => {
          let exists = data.askAuthorizeKeys.findIndex(q => q == x.authorizeKey) >= 0;
          if (exists) {
            newKey.push(x.authorizeKey);
          }
          return exists
        })

        data.askAuthorizeKeys = newKey;
        data.applyAuthorizeKeys = newKey;

      }).catch(() => {
      })
    };

    const handleAgree = () => {
      data.loading = true;
      agreeAuthorize({ ...data }).then(({ result }) => {
        data.loading = false;
        // for (var i in result) {
        //   data.callback = urlHelper.addQueryString(data.callback, i, result[i]);
        // }
        window.open(result.completeCallback, '_self');
      }).catch(() => {
        data.loading = false;
      })
    }

    const handleRefuse = () => {
      data.loading = true;
      refuseAuthorize({ ...data }).then(({ result }) => {
        data.loading = false;
        // for (var i in result) {
        //   data.callback = urlHelper.addQueryString(data.callback, i, result[i]);
        // }
        window.open(result.completeCallback, '_self');
      }).catch(() => {
        data.loading = false;
      })
    }

    onMounted(() => {
      let queryStr = window.location.search;
      let { appId, scope, sign, callback, timestamp } = route.query;
      data.queryStr = queryStr ?? '';
      data.appId = appId;
      data.askAuthorizeKeys = scope?.split(',');
      data.applyAuthorizeKeys = scope?.split(',');
      data.sign = sign;
      data.callback = callback;
      data.timestamp = timestamp;
      if (data.appId) {
        funcGetAuthorizeInfo();
      } else {
        router.go(-1);
      }
    });


    return {
      data,
      funcGetAuthorizeInfo,
      handleAgree,
      handleRefuse,
    };
  },
};
</script>
<style lang="less" scoped>
.auth-wrap {
  width: 650px;
  max-width: 100%;
}
</style>
